<script src="@/api/index.js"></script>
<script src="@/utils/request.js"></script>
<template>
	<div class="container">
		<!-- 固定背景 -->
		<div class="datiBackground"></div>
		<div style="height: 30px;"></div>
		<!-- 头部图片 -->
		<div class="top">
			<img v-if="$route.query.id == 2" src="../../assets/img/login_title.png" style="width: 72%;display: block;" alt="">
			<div v-if="$route.query.id == 1" class="time_show">{{datiTimer}}</div>
			<img v-if="$route.query.id == 1" src="../../assets/img/top1.png" style="width: 40%;display: block;margin-bottom: -50px;" alt="">
		</div>
		<!-- 答题题目 -->
		<div class="dati">
			<!-- 单选 -->
			<div class="dati_all" v-for="(item,index) in list" :key="item.id">
				<!-- 题目 -->
				<div class="dati_class">单选题</div>
				<div class="title_row">
					<label class="number">{{index+1}}.</label>
					<div class="title">
						{{item.title}}
					</div>
				</div>
				<!-- 选项 -->
				<div v-for="(x,y) in item.options" :key="x.id">
					<div :class="['option',{'active':x.active}]" @click="option(index,x,y)">
						<div class="option_key">{{x.K}}.</div>
						<div class="option_value">{{x.value}}</div>
					</div>
				</div>
			</div>
			<!-- 多选 -->
			<div class="dati_all" v-for="(item,index) in list1" :key="item.id">
				<!-- 题目 -->
				<div class="dati_class">多选题</div>
				<div class="title_row">
					<label class="number">{{list.length+index+1}}.</label>
					<div class="title">
						{{item.title}}
					</div>
				</div>
				<!-- 选项 -->
				<div v-for="(x,y) in item.options" :key="x.id">
					<div :class="['option',{'active':x.active}]" @click="checkbox(index,x,y)">
						<div class="option_key">{{x.K}}.</div>
						<div class="option_value">{{x.value}}</div>
					</div>
				</div>
			</div>
			<!-- 判断 -->
			<div class="dati_all" v-for="(item,index) in list2" :key="item.id">
				<!-- 题目 -->
				<div class="dati_class">判断题</div>
				<div class="title_row">
					<label class="number">{{list.length+list1.length+index+1}}.</label>
					<div class="title">
						{{item.title}}
					</div>
				</div>
				<!-- 选项 -->
				<div v-for="(x,y) in item.options" :key="x.id">
					<div :class="['option',{'active':x.active}]" @click="judge(index,x,y)">
						<!-- <div class="option_key">{{x.key}}</div> -->
						<div class="option_value">{{x.value}}</div>
					</div>
				</div>
			</div>
			<!-- 底部 -->
			<div class="dati_button">
				<div @click="submit()">提交</div>
			</div>
		</div>
		<img src="../../assets/img/zbfw.png" style="width: 75%;margin: 20px auto 0px;display: block;" alt="">
	</div>
</template>

<script>
	import {
		mapState,
		mapActions
	} from 'vuex'
	import {
		getStartExam, //正式答题
		getExercise, //模拟答题
		getAnswer, //答案
		getExerciseAnswer, //模拟答题答案
	} from '@/api/index.js'
	import {
		Toast
	} from 'vant';
	export default {
		name: "daTi",
		data() {
			return {
				seconds: 3600, //答题60分钟计时
				datiTimer: '',
				time: null,
				list: [],
				list1: [],
				list2: [],
				flag: false, //判断选中样式s
			}
		},
		computed: {

		},
		beforeCreate() {

		},
		created() {
			// 时间存入缓存
			/* if(localStorage.getItem('seconds') == null){
				localStorage.setItem('seconds',3600)
			} */
		},
		mounted() {
			// this.seconds = localStorage.getItem('seconds')
			this.Time()
			if (this.$route.query.id == 1) {
				if (sessionStorage.getItem('formalAnswer') == null) {
					this.getSubject()
				} else {
					this.getCacheSubject()
				}
			} else if (this.$route.query.id == 2) {
				this.getSubject();
			}
		},
		methods: {
			...mapActions(["changS", "changeTime"]),
			// 答题用时
			answerTime() {
				let m = parseInt(this.seconds / 60 % 60)
				let s = parseInt(this.seconds % 60);
				this.datiTimer = (m < 10 ? '0' + m : m) + '分' + (s < 10 ? '0' + s : s) + '秒'
				/* let h = 0,
					m = 0,
					s = 0;
				this.datiTimer = setInterval(() => {
					s++;
					if (s >= 60) {
						m += 1;
						s = 0;
						if (m >= 60) {
							h += 1;
							m = 0;
							if (h >= 24) {
								h = 0;
								m = 0;
								s = 0;
							}
						}
					}
					this.time = ((h < 10) ? ('0' + h) : h) + ':' + ((m < 10) ? ('0' + m) : m) + ':' + ((s < 10) ? (
						'0' + s) : s);
					// console.log(s)
				}, 1000) */
			},
			// 时间倒计时
			Time() {
				this.time = setInterval(() => {
					if (this.seconds == 0) {
						clearInterval(this.time)
						// this.submit()
					} else {
						this.seconds = this.seconds - 1;
						// localStorage.setItem('seconds',this.seconds)
						this.answerTime()
					}
				}, 1000)
			},
			// 单选
			option(index, x, y) {
				this.list.forEach((_item, _index) => {
					if (index == _index) {
						// console.log(_item)
						_item.options.forEach((_x, _y) => {
							if (y == _y) {
								_x.active = true;
								// console.log(_x.key,"选项")
							} else {
								_x.active = false;
							}
						})
					}
				})
			},
			//多选
			checkbox(index, x, y) {
				// console.log(this.list1[index].option[y])
				this.list1[index].options[y].active = !this.list1[index].options[y].active;
			},
			// 判断
			judge(index, x, y) {
				this.list2.forEach((_item, _index) => {
					if (index == _index) {
						// console.log(_item)
						_item.options.forEach((_x, _y) => {
							if (y == _y) {
								// console.log(_x)
								_x.active = true;
							} else {
								_x.active = false;
							}
						})
					}
				})
			},
			// 提交
			submit() {
				//获取所有答题结果
				try {
					// 单选
					let listArr = []; //定义单选题选中的内容与id
					this.list.forEach((item, index) => {
						// 获取选中的选项
						let listValue = this.list[index].options.filter(e => {
							return e.active
						})
						// console.log(listValue, "获取选中的选项（单选第0个）")
						//没有选中的值提示请选择
						if (listValue.length == 0 && this.seconds != 0) {
							this.$toast(`请检查第${index+1}道题`)
							throw Error("list")
						}
						let _answer = []
						listValue.forEach((x, y) => {
							_answer.push(x.key)
						})
						this.list[index].answer = listValue.length == 0 ? [] : _answer;
						// console.log(this.list[index].answer, "每道题选项,题目id,选中的内容")
						// 赋值给listArr
						listArr.push({
							id: this.list[index].id,
							options: this.list[index].options,
							answer: this.list[index].answer,
							title: this.list[index].title,
							typedata: this.list[index].typedata
						})
					})
					console.log(listArr, "打印listArr单选结果")

					// 多选
					let list1Arr = []; //定义多选题选中的内容与id
					this.list1.forEach((item, index) => {
						// 获取多选选中的内容
						let list1Value = this.list1[index].options.filter(e => {
							return e.active
						})
						// console.log(list1Value,"获取多选选中的内容")

						// 判断多选题是否选择
						if (list1Value.length == 0 && this.seconds != 0) {
							this.$toast(`请检查第${this.list.length+index+1}道题`)
							throw Error("list1")
						}
						let _answer = []; // 定义多选选中的值
						list1Value.forEach((x, y) => {
							_answer.push(x.key)
						})
						//赋值给_list1
						this.list1[index].answer = _answer.length == 0 ? [] : _answer
						// console.log(this.list1, "获取选中的选项")

						//给list1Arr赋值题目id，选中的答案
						list1Arr.push({
							id: this.list1[index].id,
							options: this.list1[index].options,
							answer: this.list1[index].answer,
							title: this.list1[index].title,
							typedata: this.list1[index].typedata
						})
					})
					// console.log(list1Arr, "多选题的结果")

					// 判断
					let list2Arr = []; //定义判断题选中的内容与id
					this.list2.forEach((item, index) => {
						// console.log(this.list2)
						// 获取判断选中的内容
						let list2Value = this.list2[index].options.filter(e => {
							return e.active
						})
						// console.log(list2Value, "判断题选中的内容")

						if (list2Value.length == 0 && this.seconds != 0) {
							this.$toast(`请检查第${this.list.length+this.list1.length+index+1}道题`)
							throw Error("list2")
						}
						// 赋值_list2
						this.list2[index].answer = list2Value.length == 0 ? [] : list2Value[0].key;
						// console.log(_list2)
						// 把题目的id，选中的答案赋值给list2Arr
						list2Arr.push({
							id: this.list2[index].id,
							options: this.list2[index].options,
							answer: this.list2[index].answer,
							title: this.list2[index].title,
							typedata: this.list2[index].typedata,
							judgedata: this.list2[index].judgedata
						})
					})
					// console.log(list2Arr, "判断题结果")

					//获取所有答题结果
					let data = [...listArr, ...list1Arr, ...list2Arr];
					throw Error(JSON.stringify(data))
				} catch (e) {
					//TODO handle the exception
					if (e.message === 'list' || e.message === 'list1' || e.message === 'list2') {
						return;
					} else {
						// 清除答题用时计时
						clearInterval(this.time)
						sessionStorage.setItem('time', this.datiTimer)
						if (this.seconds != 0) {
							// 答题剩余时间存入vuex中
							this.changeTime({
								answerTime: sessionStorage.getItem('time')
							})
						}

						// console.log(JSON.parse(e.message), "获取已答题的数据包含选中的值")
						// 答题历史记录存入缓存中
						sessionStorage.setItem('answerHistory', e.message)
						// 获取传给后端的值
						let data = JSON.parse(e.message).map((item, index) => {
							return {
								id: item.id,
								answer: item.answer
							}
						})
						//提交动画
						const toast =  Toast.loading({
						 	forbidClick: true,
						 	message: '正在提交...',
						 	duration: 0
						 });
						if (this.$route.query.id == 1) {
							getAnswer({
								data: JSON.stringify(data)
							}).then(res => {
								// console.log(res, "答题")
								if (res.data.code == 1) {
									this.$router.push({
										name: 'cj',
										query: {
											id: this.$route.query.id,
											points: res.data.data.points,
											tiem: res.data.data.totaltime
										}
									})
									toast.clear();
								}else{
									toast.clear();
									this.$toast(res.data.msg)
								}
							})
						} else if (this.$route.query.id == 2) {
							getExerciseAnswer({
								data: JSON.stringify(data)
							}).then(res => {
								// console.log(res, "答题")
								if (res.data.code == 1) {
									toast.clear();
									this.$router.push({
										name: 'cj',
										query: {
											id: this.$route.query.id,
											points: res.data.data.points
										}
									})
								}else{
									toast.clear();
									this.$toast(res.data.msg)
								}
							})
						}
						console.log(data, "传给后端的值")
					}
				}
			},
			//获取题目
			getSubject() {
				const toast =  Toast.loading({
					forbidClick: true,
					message: '题目加载中...',
					duration: 0
				});
				// let formalAnswer = []
				if (this.$route.query.id == 1) {
					getStartExam({
						token: localStorage.getItem('token')
					}).then(res => {
						if (res.data.code == 1) {
							let questions = res.data.data.questions;
							// formalAnswer = [...questions];
							this.addFormalAnswer(questions)
							toast.clear()
						} else {
							toast.clear()
							this.$toast(res.data.msg)
						}
						// console.log(formalAnswer, "题目")
					})
				} else if (this.$route.query.id == 2) {
					getExercise().then(res => {
						// console.log(res, "模拟答题题目")
						if (res.data.code == 1) {
							let questions = res.data.data.questions;
							// formalAnswer = [...questions];
							this.addFormalAnswer(questions);
						} else {
							this.$toast(res.data.msg)
						}
					})
				}
				/* setTimeout(() => {
					// console.log(formalAnswer, "题目2")
					formalAnswer.forEach((item, index) => {
						if (item.typedata != 3) {
							item.options = item.options.map((x, y) => {
								return {
									K: x.K,
									key: x.key,
									value: x.value,
									answer: x.answer,
									active: false
								}
							})
						} else {
							let arr = {
								key: 1,
								value: '对',
								active: false
							}
							let arr1 = {
								key: 0,
								value: '错',
								active: false
							}
							item.options.push(arr, arr1)
						}
					})
					// console.log(formalAnswer, "选项option中是否有active字段")
					// 答题存入缓存中
					sessionStorage.setItem('formalAnswer', JSON.stringify(formalAnswer))
					this.getCacheSubject()
				}, 1500) */

			},
			// 在选项中添加字段
			addFormalAnswer(formalAnswer) {
				formalAnswer.forEach((item, index) => {
					if (item.typedata != 3) {
						item.options = item.options.map((x, y) => {
							return {
								K: x.K,
								key: x.key,
								value: x.value,
								answer: x.answer,
								active: false
							}
						})
					} else {
						let arr = {
							key: 1,
							value: '对',
							active: false
						}
						let arr1 = {
							key: 0,
							value: '错',
							active: false
						}
						item.options.push(arr, arr1)
					}
				})
				// console.log(formalAnswer, "选项option中是否有active字段")
				// 答题存入缓存中
				sessionStorage.setItem('formalAnswer', JSON.stringify(formalAnswer))
				this.getCacheSubject()
			},
			// 获取vuex中保存的题目为了防止用户刷新重新获取题目
			getCacheSubject() {
				this.changS({
					formalAnswer: JSON.parse(sessionStorage.getItem('formalAnswer'))
				})
				this.list = this.$store.state.list;
				this.list1 = this.$store.state.list1;
				this.list2 = this.$store.state.list2;
				Toast.clear()
				// console.log(this.list,"单选")
				// console.log(this.$store.state.getlist1,"多选")
				// console.log(this.$store.state.getlist2,"判断")
			}
		},
		destroyed() {
			clearInterval(this.time)
			sessionStorage.removeItem('formalAnswer');
			localStorage.removeItem('seconds');
		}

	}
</script>
<style scoped lang="scss">
	.container {
		width: 100%;
		height: 100%;
		min-height: 100vh;
		padding-bottom: 50px;
		.datiBackground {
			background: url(../../assets/img/datiBackground.png) no-repeat center;
			background-size: 100% 100%;
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: -20;
		}

		.top {
			width: 100%;
			height: 260px;
			background-size: 100% 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-bottom: 50px;
			.time_show{
				width: 250px;
				height: 250px;
				background: url(../../assets/img/timeshow.png) no-repeat center;
				background-size: contain;
				margin-bottom: -100px;
				padding: 50px 0px 0px 45px;
				font-size: 50px;
			}
			.top2 {
				width: 180px;
				height: 180px;
				margin-left: 35px;
			}

			.earth {
				width: 300px;
				height: 300px;
			}
		}

		.time {
			width: 90%;
			height: 80px;
			background: #fff;
			border-radius: 20px;
			margin: 0 auto;
			font-size: 30px;
			font-weight: 600;
			line-height: 80px;

			.timeflex {
				display: flex;
				flex-direction: row;
				align-items: center;

				img {
					width: 50px;
					height: 50px;
					margin-right: 15px;
					margin-left: 30px;
				}

				.t {
					color: red;
				}
			}
		}

		.dati {
			width: 90%;
			background: #fff;
			border-radius: 20px;
			margin: 30px auto;
			height: 1100px;
			overflow-y: scroll;
			.dati_all {
				padding: 30px;
				
				.dati_class{
					font-size: 18px;
					width: 80px;
					height: 50px;
					line-height: 50px;
					text-align: center;
					background-color: #4a88ff;
					border-radius: 10px;
					color: white;
				}
				.title_row {
					width: 95%;
					display: flex;
					flex-direction: row;
					justify-content: space-around;
					padding-top: 40px;
					padding-bottom: 40px;
					margin: auto;
					font-size: 24px;
					font-weight: bold;
					.number {
						// width: 55px;
						// height: 55px;
						// background: #CCCCCC;
						// color: #fff;
						// border-radius: 50%;
						// text-align: center;
						// line-height: 55px;
						// font-size: 30px;
					}

					.title {
						width: 550px;
						// font-size: 35px;
						font-weight: 600;
						align-self: center;
					}
				}

				.option {
					display: flex;
					width: 95%;
					height: 80px;
					border-radius: 5px;
					font-size: 20px;
					align-items: center;
					margin: 0 auto 20px auto;
					background-color: #f2f2f2;
					.option_key {
						margin-left: 50px;
						font-weight: 600;
					}

					.option_value {
						margin: 20px 30px;
					}
				}


				.judge {
					width: 95%;
					height: 80px;
					border: 1px solid #DDDDDD;
					border-radius: 50px;
					font-size: 35px;
					margin: 0 auto 20px auto;
					line-height: 80px;

					.judge_value {
						line-height: 80px;
						margin-left: 30px;
					}
				}
			}

			.dati_button {
				padding: 50px 0 80px 0;
				
				div {
					margin: 0 auto;
					width: 600px;
					height: 80px;
					background-color: #52ABEF;
					color: #fff;
					font-size: 30px;
					border-radius: 10px;
					text-align: center;
					line-height: 80px;
				}
			}
		}

		.active {
			background-color: #dce8ff !important;
			color: #448cfa;
		}
	}
</style>
